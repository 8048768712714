import { createContext, useContext, useReducer } from "react";
import productList from "../assets/productList.json";
import { cartReducer } from "./CartReducer";

const Cart = createContext();

const getImagesRC = (imagePath) => {
    if (imagePath.startsWith("http")) {
    return imagePath
    };
    return process.env.PUBLIC_URL +"/imgsrc/" + imagePath;
};

const ProductListContext = ( { children }) => {
    const products = productList.map((product) => ({
    id: product.id,
    name: product.name,
    description: product.description,
    image: getImagesRC(product.image),
    link: product.link,
    }));

    const [state, dispatch] = useReducer(cartReducer, {
    products: products,
    cart: [],
    })

    return (
    <Cart.Provider value={{ state, dispatch }}>
    {children}
    </Cart.Provider>
    );
}

export default ProductListContext;

export const CartState = () => {
    return useContext(Cart);
}