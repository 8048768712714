import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "./App.css";
import { MantineProvider} from '@mantine/core';
import LandingPage from './page/LandingPage.jsx';
import ProductListPage from './page/ProductListPage.jsx';
import ProductListContext from './context/ProductListContext';

function App() {
  
  return (
    <ProductListContext>
      <MantineProvider>
        <Router>
          <Routes>
            <Route path="/" exact element={<LandingPage />} />
            <Route path="/list" element={<ProductListPage />} />
          </Routes>
        </Router>
      </MantineProvider>
    </ProductListContext>
  )
}

export default App;
