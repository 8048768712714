import { useEffect, useState } from 'react'; 
import { useMediaQuery } from 'react-responsive';

export default function useColorScheme() {
  const systemPrefersDark = useMediaQuery(
   {
    query: '(prefers-color-scheme: dark)',
   },
   undefined
  );

  const storedTheme = localStorage.getItem('theme'); 

  const [isDark, setIsDark] = useState( 
   storedTheme === 'dark' ? true : 
   storedTheme === 'light' ? false :
   systemPrefersDark !== undefined ? systemPrefersDark : false
  ); 

  useEffect(() => { 
   localStorage.setItem('theme', isDark ? 'dark' : 'light'); 
  }, [isDark]); 
 
  useEffect(() => { 
   document.body.classList.toggle('dark', isDark); 
  }, [isDark]); 

  useEffect(() => { 
   const handleStorageChange = (e) => { 
    if (e.key === 'theme') { 
     setIsDark(e.newValue === 'dark'); 
    } 
   }; 
 
   window.addEventListener('storage', handleStorageChange); 
 
   return () => { 
    window.removeEventListener('storage', handleStorageChange); 
   }; 
  }, []);

  return { isDark, setIsDark }; 
 };