import React from 'react';
import './ProductListPage.css';
import SingleProduct from '../components/SingleProduct';
import { ReactComponent as BannerLight } from "../assets/lightmode/listpage-rolling-banner-light.svg";
import { ReactComponent as BannerDark } from "../assets/darkmode/listpage-rolling-banner-dark.svg";
import { CartState } from '../context/ProductListContext';
import HeaderListPage from '../components/HeaderProductListPage';
import DarkModeToggle from '../components/ToggleThemeButton';
import useColorScheme from '../hooks/UseColorScheme';
import ToggleTheme from '../components/ToggleThemeButton';

class BackToTop extends React.Component { 
 scrollToTop() { 
  window.scrollTo({ 
   top: 0, 
   behavior: 'smooth'
  }); 
 } 

 render() { 
  return ( 
    <div>
      <p className='back-to-top' onClick={this.scrollToTop}>back to the top</p>
    </div>
  ); 
 } 
} 

const ProductListPage = () => {
  const { isDark } = useColorScheme();
  const { state:{ products }, } = CartState();
  const reversedProducts = [...products].reverse();

  return (
    <div className="List">
      <HeaderListPage />

      <div className='prodContainer'>
        {reversedProducts.map((product) => {
          return (
            <SingleProduct product={product} key={product.id} />
          );
        })}
      </div>

      <div className='BannerContainer'>
       {isDark ? <BannerDark className='banner' /> : <BannerLight className='banner' />}
      </div>

        <BackToTop />
        {/* <ToggleTheme/> */}

      <p id="copyright">Zoe's Convenience Store @ 2024</p>
    </div>
  );
}

export default ProductListPage;