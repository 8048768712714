import React from 'react';
import './LandingPage.css';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoLight } from '../assets/lightmode/mainpage-title-light.svg';
import { ReactComponent as LogoDark } from '../assets/darkmode/mainpage-title-dark.svg';
import ToggleTheme from '../components/ToggleThemeButton';
import useColorScheme from '../hooks/UseColorScheme';
import Toggle from '../TestToggle/NewToggle';

function LandingPage() {
  const { isDark } = useColorScheme();

  return (
    <div className="Landing">
      {isDark ? <LogoDark className='ZoeLogo' /> : <LogoLight className='ZoeLogo' />}
      <Link className='Enter' to="/list">
        [Enter]
      </Link>
      <ToggleTheme/>
    </div>
  );
}

export default LandingPage;