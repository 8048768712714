import React from 'react'; 
import useColorScheme from '../hooks/UseColorScheme';

const ToggleTheme = () => {
 const { isDark, setIsDark } = useColorScheme();
 const handleToggleTheme = () => { 
  setIsDark(!isDark);
  console.log('Is darkmode:', isDark) 
  // Toggle the theme between dark and light 
 }; 

 return (
    <div
      onClick={handleToggleTheme} // Call the toggle function on click
      style={{ cursor: 'pointer', fontSize: '14px', fontStyle: 'italic' }} // Make it look clickable
    >
      {isDark ? '2AM' : '2PM'} {/* Show "AM" in light mode and "PM" in dark mode */}
    </div>
 );
};

export default ToggleTheme;