import React from 'react';
import './HeaderProductListPage.css';
import { ReactComponent as LogoLight } from '../assets/lightmode/listpage-spin-logo-light.svg';
import { ReactComponent as TitleLight } from '../assets/lightmode/listpage-title-web-light.svg';
import { ReactComponent as MobileTitlelight } from '../assets/lightmode/listpage-title-mobile-light.svg';
import { ReactComponent as LogoDark } from '../assets/darkmode/listpage-spin-logo-dark.svg'; 
import { ReactComponent as TitleDark } from '../assets/darkmode/listpage-title-web-dark.svg'; 
import { ReactComponent as MobileTitleDark } from '../assets/darkmode/listpage-title-mobile-dark.svg';

import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import useColorScheme from '../hooks/UseColorScheme';

export default function HeaderListPage () {
  const { isDark } = useColorScheme();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <header className="List-header">
      <Link to="/"> 
        {isDark ? (
          <LogoDark key="dark-logo" className="List-logo" alt="logo" />
        ) : (
          <LogoLight key="light-logo" className="List-logo" alt="logo" />
        )}
      </Link> 

      {/* <TitleLight className='Title' /> */}
      {isMobile ? (
        isDark ? (
          <MobileTitleDark key="dark-mobile-title" className="Title-Mobile" alt="mobile-title" />
        ) : (
          <MobileTitlelight key="light-mobile-title" className="Title-Mobile" alt="mobile-title" />
        )
      ) : (
        isDark ? (
          <TitleDark key="dark-title" className="Title" alt="title" />
        ) : (
          <TitleLight key="light-title" className="Title" alt="title" />
        )
      )}
    </header>
  );
}
