import React, { useEffect, useState } from "react";
import './SingleProduct.css';
import './SingleProduct.css'
import { CartState } from '../context/ProductListContext';

const SingleProduct = ({ product: prod }) => {
    const { state: { cart }, dispatch} = CartState()

      return (
            <div className="product">
              <img src={prod.image} alt={prod.name} loading="lazy" />
              <h2>
                <a href={prod.link} target="_blank" rel="noopener noreferrer">
                  {prod.name}
                </a>
              </h2>
              <p>{prod.description}</p>
            </div>
          )
        }
        
export default SingleProduct;
